import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '/src/components/layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion, faSquarePlus, faSection } from '@fortawesome/free-solid-svg-icons'

const OmPage = () => {
	return (
		<Layout pageTitle="Om Brfify">

			<p>Med bultande hjärta för digitalisering och passion för att förenkla vardagen blev det givet för Erik, Brfifys grundare, att bygga Brfify-plattformen. Då som nu hämtas inspiration och behov från vardaliga erfarenheter som flerårig ordförande och styrelsemedlem. "För brf:er av brf" skulle man kunna säga!</p>

			<p>Även om fokus ligger på att förenkla vardagen för Sveriges bostadsrättsföreningar så fungerar Brfify även mycket bra för små till medelstora privata fastighetsägare med hyresrätter. Alla kunder, oavsett ägandeform, är varmt välkomna att förmedla feedback och önskemål om förändringar i Brfify!</p>

			<p>Brfify tillhandahålls av "Aktiebolag Erik Harlefelt" med organisationsnummer 559389-7241. Läs mer på <a href="https://iterik.se" target="_blank">iterik.se</a></p>

			<p>Brfify uttalas brf-ify och namnet är inspirerat av andra digitala tjänster med samma suffix.</p>
			
			<p><br/></p>

			<div className="buttons">
				<Link to="/skaffa">
					<FontAwesomeIcon icon={faSquarePlus} />
					<p>Skaffa<br/>Brfify</p>
				</Link>
				<Link to="/support/fragor-svar">
					<FontAwesomeIcon icon={faCircleQuestion} />
					<p>Frågor &amp; svar</p>
				</Link>
				{/*
				<Link to="/om/historia">
					<FontAwesomeIcon icon={faMonument} />
					<p>Brfifys<br/>historia</p>
				</Link>
				* /}
				<Link to="/om/villkor">
					<FontAwesomeIcon icon={faSection} />
					<p>Villkor &amp; personuppgifter</p>
				</Link>
				*/ }
			</div>

		</Layout>
	)
}

export default OmPage